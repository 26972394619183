import React from "react"
import Container from "../components/container"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import ReCAPTCHA from "react-google-recaptcha";

export default () => (
    <Container>
        <Helmet>    
            <title>An Exquisite Meal | Watch the Film </title>
            <meta name="description" content="An ambitious dark comedy thriller." />
            <meta name="google-site-verification" content="9Mpk8j_WsmSP30bEXxV4apqdr2VVJWB5ev4RwtxDtoc" />
        </Helmet>
 
        <div class="sub-container-home">
        
            <h1 class="headline-homepage">An ambitious dark comedy, full of surprises.</h1>    
        
            <p class="description"><em>An Exquisite Meal</em> is a sharply satirical genre-bending film that, despite its intimate setting, takes the viewer on a strange journey that’s full of punchlines, twists, and striking images.</p>
            
            
                

            <div class="laurels">
                <img src="../CQFF_Laurels_2020Selection.png" width="300"/>
            </div>
            
            <h2>Join the mailing list and get access to a secret trailer.</h2>
            <div class="form-box-homepage">
                <form name="newsletter" method="POST" action="/thank-you-newsletter/" encType="application/x-www-form-urlencoded" data-netlify-recaptcha="true" data-netlify="true">
                    
                    <input type="hidden" name="form-name" value="newsletter"/>
                    <p><label>Your Name: <input type="text" name="name" /></label></p>
                    <p><label>Your Email: <input type="email" name="email" /></label></p>
                    <br />
                    <ReCAPTCHA sitekey="6Ld3X9UUAAAAAKdmMfH-uTHWSOb0Jkvhz5PJaVD8" />
                    <br /><br />
                    <button type="submit" class="btn btn-green">Send me the secret trailer!</button>    
                </form>
            </div>
            <br/>

            
        </div>
        <Footer>
        </Footer>
    </Container> 
)


